import React from "react";
import { graphql } from "gatsby";
import Helmet from "react-helmet";

import Layout from "../components/Layout";

class VoeuxTemplate extends React.Component {
  render() {
    return (
      <div className="voeux-wrapper flex-column justc-center alitems-center">
        <h2 className="mgb-m bold-weight">L'équipe Electre vous présente ses meilleurs vœux</h2>
        <div className="wishes-video-wrapper">
          {/*eslint-disable */}
          <video controls poster="/files/voeux-poster.png"> 
            <source src="/files/carte-de-voeux-electre-web.mp4" type="video/mp4"/>
          </video>
          {/*eslint-enable */}
        </div>
      </div>
    )
  }
}

const VoeuxPage = ({data}) => {
  const { footerData, navbarData } = data;
  return (
    <Layout footerData={footerData} navbarData={navbarData}>
      <Helmet>
        <title>Electre - Vœux 2020</title>
      </Helmet>
      <VoeuxTemplate/>
    </Layout>
  )
}

export default VoeuxPage;


export const voeuxPageQuery = graphql`
  query VoeuxPage {
    ...LayoutFragment
  }
`